import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h2" components={components}>{`1. Content`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.1.`}</MDXTag>{` This service should not be used to publish content to suggest violence, discrimination, illegal acts, transgression and hatred.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.2.`}</MDXTag>{` You must not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate people or entities via this Service.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.3.`}</MDXTag>{` We are not responsible for the content published through our service.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.4.`}</MDXTag>{` The contents inserted in our service (text, images, audio and others) by users is used to generate a themed animation.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.5.`}</MDXTag>{` Adaptations may happen depending on the display resolution and size of the inserted contents.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.6.`}</MDXTag>{` About content removal: We don't register your login when you create the content, so if you want to request a content removal
we may request additional information to prove that you are the author of the content.
The information we need is the date and time (including timezone) you created the content.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`2. Third-parties`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.1.`}</MDXTag>{` The content published by users in our service may contain links to third-party web sites or services that are not owned or operated by us.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.2.`}</MDXTag>{` We have no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services associated with the content published by users in our service.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.3.`}</MDXTag>{` You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`3. Video download`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.1.`}</MDXTag>{` Our service can render your creation to a video customized with your content.
The video file is in MP4 format and you should have a video player and a device that is compatible to reproduce this media format.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.2.`}</MDXTag>{` We do not guarantee that the rendered videos are identical to the creations in this service.
The quality may differ depending on the device on which the content is displayed.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.3.`}</MDXTag>{` The generated videos are made automatically and take 10 minutes to be delivered.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.4.`}</MDXTag>{` We are not responsible for any errors (typos, misspellings) made when creating your content, the rendered video content is reproduced exactly how it was received.
Any fixes requested in a rendered text will be subjected to extra costs.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`4. Payments`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.1.`}</MDXTag>{` You can pay to generate your custom logo in video and we will deliver it to your email address used in the payment.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.2.`}</MDXTag>{` If you use PayPal, we strongly recommend you to use the same email you have used in your PayPal account used to pay to ask for the video rendering.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.3.`}</MDXTag>{` Multiple payments that add up to the minimum value will not be accepted to get the video, due to taxes and the payments being processed independently.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.4.`}</MDXTag>{` When confirmed, you should receive a confirmation email about your payment and your video being processed in the email address informed, if not, please check all the requirements and contact us if needed.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.5.`}</MDXTag>{` If you want to pay a greater value to get more videos you can contact us for further details.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.6.`}</MDXTag>{` We also use Stripe as payment gateway to process payments via credit card.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.7.`}</MDXTag>{` We don't store credit card details and we don't charge without the user action and permission.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.8.`}</MDXTag>{` We don't refund orders by any mistake caused by the user who paid for it, like typing errors.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.9.`}</MDXTag>{` We don't refund orders that already have been sent if they are in accordance with these terms.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.10.`}</MDXTag>{` Duplicate payments for the same video may be refunded but applied taxes are non-refundable.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`5. Video Download Delivery`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5.1.`}</MDXTag>{` We cannot ensure the video will be properly delivered when it was caused by any issues that
are beyond the domains of our service like: misspelled email, user inbox is full, inactive user account,
the email service sent the video notification email to spam).`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5.2.`}</MDXTag>{` Please, check if your account is able to receive our email and if you type your email correctly.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5.3.`}</MDXTag>{` If you paid, pay attention to the estimated time to receive it informed in the confirmation email.
You should get your video within 1-2 days, but the time may vary on how many video requests were made recently.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5.4.`}</MDXTag>{` Your rendered video will be available at the same link of your creation at the Download page.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5.5.`}</MDXTag>{` We sent the link to download your video via email, and the video file is available for one month after it is sent.
After this period it will be erased from our servers.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5.6.`}</MDXTag>{` No corrections, changes or refunds will be made to the video after the period of one month after delivery.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`6. Content Reproduction`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`6.1.`}</MDXTag>{` We are not responsible for the reproduction of videos on third-party services.
The videos may have copyrighted content like images and music, and its reproduction may depend on the terms of service of third-party applications.
The reproduction of the videos for commercial use is subject to these copyrights.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`6.2.`}</MDXTag>{` We don't own any of these copyrights and we are not related to their respective owners.
The service is developed and maintained by fans.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`6.3.`}</MDXTag>{` Sharing the rendered video on services like Facebook and YouTube may violate copyright, which is not allowed by such services.
In those cases, it may be better to use the shareable link to your creation with the service.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`7. House of the Dragon Intro Creator`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.1.`}</MDXTag>{` This website is not related or to HBO.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.2.`}</MDXTag>{` The music used in the background video are copyrights of Universal Music.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      