import React from 'react';

import ProductPageLayout from '../components/ProductPageLayout';
import faq from '../faq/house-of-the-dragon';
import Terms from '../termsOfService/house-of-the-dragon.mdx';

const BreakingBadPage = () => (
  <ProductPageLayout
    title="House of the Dragon Intro Creator"
    emailAlias="support"
    website="https://houseofthedragonintrocreator.kassellabs.io"
    faq={faq}
    mergeTerms={false}
    mergeFaq={false}
    terms={<Terms />}
  />
);

export default BreakingBadPage;

